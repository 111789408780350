<template>
  <div class="BillTypeList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" />
      </template>
      <template #searchSlot>
        <v-select2 label="单据类型" v-model="searchParams.billtypeId" v-bind="billTypeParams"/>
        <v-select2 label="关联流程" v-model="searchParams.processId" v-bind="processParams"/>
        <v-select label="单据状态" v-model="searchParams.isEnable" :options="billTypeConfigOps" />
        <v-select2 label="所属组织" v-model="searchParams.orgId" v-bind="orgParams" />
        <!-- <v-datepicker label="创建时间"
                      :startTime.sync="searchParams.intimeStart"
                      :endTime.sync="searchParams.intimeEnd"></v-datepicker> -->
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)" />
        <v-button text="删除" type="text" permission="delete" @click="handleDelete(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { getBillTypeConfigListURL, deleteURL, batchDeleteURL, getBilltypeListURL, getProcessListURL } from './api'
import { billTypeConfigMap, billTypeConfigOps } from './map'
import { orgParams } from 'common/select2Params'

export default {
  name: 'billTypeConfig',
  data () {
    return {
      searchUrl: getBillTypeConfigListURL,
      maxDate: new Date(),
      orgParams,
      billTypeParams : {
        searchUrl: getBilltypeListURL,
        request: {
          text: 'name',
          value: 'id'
        },
        response: {
          text: 'billtypeName',
          value: 'id'
        },
      },
      processParams: {
        searchUrl: getProcessListURL,
        request: {
          text: 'name',
          value: 'id'
        }
      },
      billTypeConfigOps: billTypeConfigOps(1),
      searchParams: {
          billtypeCode: '',
          billtypeName: '',
          isEnable: undefined,
          orgId: undefined
      },
      headers: [
        {
          prop: 'billtypeId',
          label: '单据ID'
        },
        {
          prop: 'processId',
          label: '流程ID'
        },
        {
          prop: 'isEnable',
          label: '状态',
          formatter (row) {
            return billTypeConfigMap[row.isEnable]
          }
        },
        {
          prop: 'inTime',
          label: '创建时间'
        },
        {
          prop: 'inUser',
          label: '创建人'
        }
      ]
    }
  },
  computed: {
    visible () {
      const userType = this.$store.state.userInfo.userType
      return userType === '106'
    }
  },
  methods: {
    create () {
      if (this.visible) {
        this.$router.push({
          name: 'billTypeConfigForm'
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    edit (row) {
      if (this.visible) {
        this.$router.push({
          name: 'billTypeConfigForm',
          query: {
            id: row.id
          }
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    async handleDelete (row) {
        const result = await this.$confirm('确认删除？', {
            title: '提示'
        })
        if(result) {
            var that = this
            let params = {
                id: row.id
            }
            that.$axios.get(deleteURL, { params: params }).then((res) => {
                if (res.status === 100) {
                    that.$message.success("删除成功")
                    that.$refs.list.searchData()
                } else {
                    // that.$message.warning(res.msg)
                }
            })
        }
       
    },

  }
}
</script>
