var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "BillTypeList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          isMultiSelect: true,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "单据类型" },
                      model: {
                        value: _vm.searchParams.billtypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "billtypeId", $$v)
                        },
                        expression: "searchParams.billtypeId",
                      },
                    },
                    "v-select2",
                    _vm.billTypeParams,
                    false
                  )
                ),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "关联流程" },
                      model: {
                        value: _vm.searchParams.processId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "processId", $$v)
                        },
                        expression: "searchParams.processId",
                      },
                    },
                    "v-select2",
                    _vm.processParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "单据状态", options: _vm.billTypeConfigOps },
                  model: {
                    value: _vm.searchParams.isEnable,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "isEnable", $$v)
                    },
                    expression: "searchParams.isEnable",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属组织" },
                      model: {
                        value: _vm.searchParams.orgId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "orgId", $$v)
                        },
                        expression: "searchParams.orgId",
                      },
                    },
                    "v-select2",
                    _vm.orgParams,
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "删除", type: "text", permission: "delete" },
                  on: {
                    click: function ($event) {
                      return _vm.handleDelete(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }